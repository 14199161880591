<template>
  <div class="notices">
    <div class="my-courses-header">
      <v-badge :value="unreadNoticeCount > 0"
               :content="unreadNoticeCount"
               inline
               color="primary">
        Notice
      </v-badge>
    </div>
    <NoticeList show-course-name></NoticeList>
  </div>
</template>

<script>
import NoticeList from "../components/NoticeList";
import {computed} from "@vue/composition-api";
import {Notice} from "../store/models";

export default {
  name: 'Notices',
  components: {NoticeList},
  setup() {
    const unreadNoticeCount = computed(() => {
      return Notice.query().where((n) => !n.read_at).count();
    })

    return {
      unreadNoticeCount,
    }
  },
}
</script>

<style lang="less">
.notices {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background: white;
  max-width: 100%;

  .my-courses-header {
    padding: 16px 16px 0 16px;
    font-size: 200%;
    font-weight: 500;
  }

  .course-notice-table-wrapper {
    flex: 1 1 auto;
  }
}
</style>
