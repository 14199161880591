import {ACTION_TYPES} from "@/store/types";

export const accountModule = {
    state: {
        isLoggedIn: false,
        account: null,
        afterLogin: null,
    },
    mutations: {
        setAccount(state, account) {
            state.isLoggedIn = true;
            state.account = account;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.account = null;
        },
        setAfterLogin(state, afterLogin) {
            state.afterLogin = afterLogin;
        },
    },
    actions: {
        [ACTION_TYPES.GET_ME]: {
            root: true,
            async handler({dispatch}) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {url: 'me/'});
                dispatch(ACTION_TYPES.SET_ME, response.body.account);
                return response;
            },
        },
        [ACTION_TYPES.SET_ME]: {
            root: true,
            handler({commit}, account) {
                commit('setAccount', account);
            },
        },
        [ACTION_TYPES.LOGIN]: {
            root: true,
            async handler({dispatch}, params) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {url: 'login/', params});
                dispatch(ACTION_TYPES.SET_ME, response.body.account);
                return response;
            },
        },
        [ACTION_TYPES.LOGOUT]: {
            root: true,
            async handler({commit, dispatch}) {
                try {
                    await dispatch(ACTION_TYPES.CALL_API, {url: 'logout/', params: {}, opt: {throw_error: true}});
                } catch (e) {
                    ''
                }
                commit('logout');
            }
        },
        [ACTION_TYPES.SET_AFTER_LOGIN]: {
            root: true,
            async handler({state, dispatch, commit}, params) {
                commit('setAfterLogin', params);
            }
        },
        [ACTION_TYPES.CLEAR_AFTER_LOGIN]: {
            root: true,
            async handler({state, dispatch, commit}) {
                commit('setAfterLogin', null);
            }
        },
    },
    getters: {
        account(state) {
            return state.account;
        },
        afterLogin(state) {
            return state.afterLogin;
        },
    },
};
