<template>
  <v-dialog v-model="dialogShown"
            persistent
            fullscreen
            content-class="login-dialog"
            transition="fade-transition">
    <v-container class="login">
      <v-row>
        <v-col>
          <v-img src="../assets/logo.png"
                 height="10vh"
                 max-height="200px"
                 contain></v-img>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col class="text-h5 text-center">
          ArchSD TMM
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field outlined
                        :label="$t('login.username')"
                        v-model="username"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field outlined
                        :label="$t('login.password')"
                        v-model="password"
                        type="password"
                        @keyup.enter="login"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block
                 color="primary"
                 dark
                 @click="login">
            {{ $t('login.title') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block
                 color="primary"
                 dark
                 @click="$loginTeamNote">
            TEAMNOTE OAUTH
          </v-btn>
        </v-col>
      </v-row>
<!--      <v-row>-->
<!--        <v-col>-->
<!--          <v-btn block-->
<!--                 color="#5bc0de"-->
<!--                 dark-->
<!--                 @click="$loginTeamNote">-->
<!--            {{ $t('login.login_with_teamnote') }}-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--      </v-row>-->
    </v-container>
  </v-dialog>
</template>

<script>
import {ref, watch} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";
import {DOMAIN_ID} from "@/constants";

export default {
  name: 'LoginModal',
  props: {
    shown: Boolean,
  },
  setup(props, {root}) {
    const dialogShown = ref(props.shown);
    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue && !oldValue) {
        username.value = '';
        password.value = '';
      }

      dialogShown.value = newValue;
    });

    const username = ref('');
    const password = ref('');
    const login = async function () {
      await root.$store.dispatch(ACTION_TYPES.LOGIN, {
        domain_id: DOMAIN_ID,
        username: username.value,
        password: password.value,
      });

      password.value = '';
    };

    return {
      dialogShown,
      username,
      password,
      login,
    }
  }
}
</script>

<style lang="less">
.login-dialog {
  background-color: white;
  background-image: url('../assets/background.png');
  background-size: cover;
  background-position: top;

  .login {
    max-width: 750px;

    .login-footer {
      font-size: 60%;
    }
  }
}
</style>
