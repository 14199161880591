export const ENROLLMENT_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    WALK_IN: 'WALK_IN',
    CANCELLED: 'CANCELLED',
}


export const EVALUATION_QUESTION_TYPE = {
    LABEL: 'LABEL',
    TEXT: 'TEXT',
    LONGTEXT: 'LONGTEXT',
    CHECKBOX: 'CHECKBOX',
    RADIO: 'RADIO',
    DROPDOWN: 'DROPDOWN',
    RATING: 'RATING',
}


export const JWT_KEY = {
    EVENT_ID: 'evt',
    SESSION_ID: 'ssn',
}
