import VuexORM, {Model} from '@vuex-orm/core'

const database = new VuexORM.Database();

export class Event extends Model {
    static entity = 'events'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            category: this.attr(),
            name: this.attr(),
            name_en: this.attr(),
            name_zh: this.attr(),
            start: this.attr(),
            end: this.attr(),
            venue: this.attr(),
            venue_en: this.attr(),
            venue_zh: this.attr(),
            evaluation: this.attr(),
            can_walk_in: this.attr(),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class Attendance extends Model {
    static entity = 'attendances'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            enrollment: this.attr(),
            session: this.attr(),
            checkin_time: this.attr(),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class Notice extends Model {
    static entity = 'notices'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            announcement: this.attr(),
            read_at: this.attr(),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

database.register(Event);
database.register(Attendance);
database.register(Notice);

export default VuexORM.install(database);
