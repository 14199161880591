<template>
  <div class="course-notice-table-wrapper">
    <div v-for="notice in notices"
         :key="notice.id"
         class="course-notice-wrapper"
         @click="onRowClick(notice)">
      <div class="course-notice-unread"
           v-if="!notice.read_at"></div>
      <div class="course-notice-content-wrapper">
        <div class="course-notice-content-first-row">
          <div class="course-notice-course-name"
               v-if="tryGetCourseName(notice)">
            {{ tryGetCourseName(notice) }}
          </div>
          <div class="course-notice-published-at">{{ $parseDate(notice.announcement.published_at, 'yyyy-MM-dd') }}</div>
        </div>
        <div class="course-notice-title">{{ notice.announcement.title }}</div>
        <div class="course-notice-content">{{ notice.announcement.content }}</div>
      </div>
    </div>

    <NoticeModal :shown="noticeModalShown"
                 :receipt-id="showingReceiptId"
                 @dismiss="onNoticeModalDismiss"></NoticeModal>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "../store/types";
import NoticeModal from "./NoticeModal";
import {Event, Notice} from "../store/models";

export default {
  name: 'NoticeList',
  components: {NoticeModal},
  props: {
    courseId: String,
    showCourseName: Boolean,
  },
  setup(props, {root}) {
    const notices = computed(() => {
      let notice = Notice.query().withAllRecursive();

      if (props.courseId) {
        notice = notice.where((n) => n.announcement.event === props.courseId)
      }

      return notice.orderBy('created_at', 'desc').all()
    });
    // const getNotices = async function () {
    //   const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
    //     url: 'client/events/notice/',
    //     params: {
    //       'event_id': props.courseId,
    //     },
    //   });
    //
    //   if (response.body.success) {
    //     notices.value = response.body.notice;
    //   }
    // }

    const tryGetCourseName = function (notice) {
      if (props.showCourseName) {
        const event = Event.find(notice.announcement.event);

        if (event) {
          return event.name;
        }
      }
    }

    const showingReceiptId = ref(null);
    const noticeModalShown = ref(false);
    const onRowClick = function (notice) {
      showingReceiptId.value = notice.id;
      noticeModalShown.value = true;
    };
    const onNoticeModalDismiss = function () {
      showingReceiptId.value = null;
      noticeModalShown.value = false;
      root.$store.dispatch(ACTION_TYPES.GET_NOTICE);
    }

    onMounted(() => {
      root.$store.dispatch(ACTION_TYPES.GET_NOTICE, true);
    })

    return {
      notices,

      tryGetCourseName,
      showingReceiptId,
      noticeModalShown,
      onRowClick,
      onNoticeModalDismiss,
    }
  }
}
</script>

<style lang="less">
.course-notice-table-wrapper {
  background: white;

  .course-notice-wrapper {
    display: flex;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    overflow-y: auto;

    .course-notice-unread {
      flex: 0 0 auto;
      background-color: var(--v-primary-base);
      width: 5px;
    }

    .course-notice-content-wrapper {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      padding: 12px;
      overflow: hidden;

      .course-notice-content-first-row {
        display: flex;
        padding-bottom: 8px;

        .course-notice-course-name {
          flex: 1 1 0;
          font-size: 66.66%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--v-primary-base);
          padding-right: 8px;
        }

        .course-notice-published-at {
          flex: 0 0 auto;
          text-align: right;
          font-size: 77.77%;
        }
      }

      .course-notice-title {

      }

      .course-notice-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 77.77%;
        opacity: 0.5;
      }
    }
  }
}
</style>
