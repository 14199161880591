import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import ormPlugin from './models'
import {accountModule} from "./modules/account";
import {snackBarModule} from "./snackBar";
import {apiModule} from "./modules/api";
import loadingSpinner from "./modules/loadingSpinner";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        account: accountModule,
        snackBar: snackBarModule,
        api: apiModule,
        loadingSpinner: loadingSpinner,
    },
    plugins: [
        ormPlugin,
        vuexLocal.plugin,
    ],
})
