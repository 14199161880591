import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n);

const messages = {
    "en": {
        login: {
            title: 'Login',
            username: 'Username',
            password: 'Password',
            login_with_teamnote: 'Login with TeamNoteOAuth'
        },
        account: {
            name: 'Name',
            hrms_id: 'GovHRMSId',
            title: 'Title',
            email: 'Email',
            teamnote_username: 'User ID',

            hrms_import: 'Import GovHRMSId',
        },
        material: {
            created_at: 'Uploaded At',
            add_folder: 'Create Folder',
            folder_name: 'Folder Name',
            upload_file: 'Upload File',
            folder_file_count: '{count} File(s)',
            rename: 'Rename',
            rename_success_msg: 'Renamed Successfully',

            delete_confirm_msg: 'Confirm Delete File? It cannot be recovered.',
            delete_folder_confirm_msg: 'Confirm Delete Folder and its Content? It cannot be recovered.',
            delete_success_msg: 'Deleted',
        },
        file_upload: {
            title: 'Upload File',
            parent: 'Parent Folder',
            name: 'File Name',
            size: 'File Size',
            type: 'File Type',
            dropzone_msg: 'Drop files here or click to browse',
            dropzone_hint: 'Max. size of file: <b>10MB</b>',
        },
        scan_qr_code: {
            checkin_confirm_msg: 'Check-in Session?',
            checkin_success_msg: 'Check-in Successful',
        },
        //
        cancel: 'Cancel',
        check_in: 'Check In',
        delete: 'Delete',
        download: 'Download',
        edit: 'Edit',
        import: "Import",
        no: 'No',
        overall: 'Overall',
        ok: 'OK',
        people: 'People',
        save: 'Save',
        revert: 'Revert',
        yes: 'Yes',
        error_messages: {
            default: 'Something went wrong. Please try again later. {status_code}',
            object_not_found: 'Object Not Found',
            invalid_event_id: 'Invalid Course',
            invalid_session_id: 'Invalid Lesson',
            not_yet_enrolled: 'The course is for enrolled staff only. Your enrollment is not successful.',
            invalid_receipt_id: 'Invalid Notice Receipt',
            invalid_course_excel: 'Invalid Course Excel',
            invalid_hrms_excel: 'Invalid GovHRMSId Excel',
            invalid_enrollment_excel: 'Invalid Enrollment Excel',
            invalid_token: 'Invalid Check-in QR Code',
            evaluation_not_published: 'Evaluation cannot be submitted at this time',
            evaluation_submission_ended: 'Evaluation submission period has closed',
        }
    },
    "zh": {},
};

const i18n = new VueI18n({
    locale: 'en',
    messages,
});

export default i18n;
