import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api';
import VueResource from 'vue-resource';
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./plugins/i18n";
import vuetify from './plugins/vuetify';
import {DateTime} from "luxon";
import {ACTION_TYPES} from "./store/types";
import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.config.productionTip = false

Vue.use(VueCompositionApi);
Vue.use(VueResource);
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VuetifyDialog, {
  context: {
    i18n,
    vuetify,
    store
  }
});

Vue.use({
  install() {
    //Event Hub
    Vue.prototype.$eventHub = new Vue();

    // Copy to Clipboard
    Vue.prototype.$copyToClipboard = async function (text) {
      let v = this;

      async function fallbackCopyTextToClipboard(text) {
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          let successful = document.execCommand('copy');
          let msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);

        v.$showAlertMessage('Copied!');
      }

      if (!navigator.clipboard) {
        await fallbackCopyTextToClipboard(text);
        return;
      }

      try {
        await navigator.clipboard.writeText(text);
        this.$showAlertMessage('Copied!');
        console.log('Async: Copying to clipboard was successful!');
      } catch (err) {
        console.error('Async: Could not copy text: ', err);
      }
    };

    // Loading Spinner
    Vue.prototype.$startLoading = function (timeout) {
      this.$store.commit('startLoading', timeout);
    };
    Vue.prototype.$stopLoading = function () {
      this.$store.commit('stopLoading');
    };

    // API Call
    Vue.prototype.$downloadFile = async function (file_id) {
      let path = `file/${file_id}`;

      let response = await this.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: path,
        params: {},
        opt: {extra: {responseType: 'arraybuffer'}},
      });

      let blob = new Blob([response.data], {type: response.headers.get('content-type')});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = response.headers.get('content-filename');
      link.click();
    }
    Vue.prototype.$handleErrorMessage = function (response) {
      if (typeof response.body === 'object') {
        if ('msg' in response.body) {
          let msg = response.body.msg;
          if (i18n.te('error_messages.' + msg)) {
            return this.$showAlertMessage(i18n.t(`error_messages.${msg}`))
          } else {
            return this.$showAlertMessage(msg)
          }
        }
        return this.$showAlertMessage(i18n.t('error_messages.default', {status_code: `(${response.status})`}))
      } else {
        return this.$showAlertMessage(i18n.t('error_messages.default', {status_code: `(${response.status})`}))
      }
    }

    Vue.prototype.$convertFileToBase64 = async function (file) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      return await toBase64(file);
    };

    Vue.prototype.$convertFileToBinaryString = async function (file) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      return await toBase64(file);
    };

    Vue.prototype.$showAlertMessage = function (message) {
      this.$dialog.error({text: message, showClose: false});
    };

    Vue.prototype.$isTeamNote = function () {
      return Boolean(window.tnConnector);
    };

    Vue.prototype.$loginTeamNote = async function () {
      const response = await store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'oauth/',
      });
      const host = response.body.host;
      const clientId = response.body.client_id;
      const preferredMethod = response.body.preferred_method;

      setTimeout(() => {
        let afterLogin = window.location.origin + window.location.pathname + window.location.hash;
        window.location.href = `${host}/oauth2/auth?response_type=id_token&client_id=${clientId}&scope=user_name&redirect_uri=${encodeURIComponent(afterLogin)}&prefer=${encodeURIComponent(preferredMethod)}`;
      }, 50);
    };

    Vue.prototype.$parseDate = function (d, fmt = 'yyyy-MM-dd HH:mm') {
      return DateTime.fromISO(d).toFormat(fmt);
    };

    Vue.prototype.$parseFileSize = function (bytes, si=false, dp=1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }

      const units = si
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


      return bytes.toFixed(dp) + ' ' + units[u];
    };

    Vue.prototype.$me = function () {
      return this.$store.getters.account;
    }
  },
});

export const HOST = '/api/';

setTimeout(async () => {
  if (window.tnConnector) {
    window.tnConnector.init({
      featureId: 'tmm',
    }, () => {
      initVue();
    }, (error) => {
      console.log(error);
    });
  } else {
    initVue();
  }
}, 50)

function initVue() {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}


