export default {
    state: {
        // isLoading: false,
        requestCount: 0,
    },
    mutations: {
        startLoading(state, timeout) {
            state.requestCount += 1;
            // state.isLoading = true;

            var store = this;
            setTimeout(function () {
                store.commit('stopLoading');
            }, timeout || 30000);
        },
        stopLoading(state) {
            // state.isLoading = false;
            state.requestCount = Math.max(state.requestCount - 1, 0);
        },
    },
};