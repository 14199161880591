import {ACTION_TYPES} from "./types";

export const snackBarModule = {
    state: {
        content: '',
    },
    mutations: {
        setContent(state, content) {
            state.content = content;
        },
        clearContent(state) {
            state.content = '';
        },
    },
    actions: {
        [ACTION_TYPES.SHOW_SNACKBAR]: {
            root: true,
            handler({commit}, content) {
                commit('setContent', content);
            },
        },
        [ACTION_TYPES.HIDE_SNACKBAR]: {
            root: true,
            handler({commit}, content) {
                commit('clearContent', content);
            },
        },
    },
    getters: {
        snackBarContent(state) {
            return state.content;
        },
    },
}
