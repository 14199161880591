<template>
  <div class="course-lessons">
    <div v-for="attendance in attendances"
         :key="attendance.id"
         class="course-lesson">
      <div class="course-lesson-title">
        {{ attendance.session.name }}
      </div>
      <div class="course-lesson-time"
           v-if="attendance.session.start">
        <v-icon color="primary">mdi-calendar-month-outline</v-icon>
        {{ $parseDate(attendance.session.start, 'yyyy/MM/dd HH:mm') }}
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "../store/types";
import {Attendance} from "../store/models";

export default {
  name: 'CourseLessons',
  props: {
    courseId: String,
  },
  setup(props, {root, emit}) {
    const attendances = computed(() => {
      return Attendance.query()
          .orderBy((a) => a.session.start, 'asc')
          .orderBy((a) => a.session.name, 'asc').all();
    })

    watch(()=> props.courseId, (newValue) => {
      if (newValue) {
        root.$store.dispatch(ACTION_TYPES.GET_ATTENDANCE, props.courseId);
      }
    }, {immediate: true})

    // onMounted(() => {
    //   root.$store.dispatch(ACTION_TYPES.GET_ATTENDANCE, props.courseId);
    // });

    return {
      attendances,
    }
  }
}
</script>

<style lang="less">
.course-lessons {
  background: white;
  overflow-y: auto;

  .course-lesson {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    gap: 8px 0;

    .course-lesson-title {
      font-weight: 500;
    }

    .course-lesson-time {

    }
  }
}
</style>
