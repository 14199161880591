<template>
  <v-dialog v-model="dialogShown"
            transition="scroll-x-transition"
            fullscreen
            persistent
            class="pa-0">
    <div class="notice-modal">
      <v-app-bar flat
                 dense
                 class="notice-modal-top-bar">
        <div class="notice-modal-back-btn"
             @click="$emit('dismiss')">
          <v-icon>mdi-chevron-left</v-icon>
        </div>
        <div class="notice-modal-title">Notice</div>
      </v-app-bar>
      <div class="notice-modal-content-wrapper">
        <div class="notice-modal-course-name"
             v-if="event">
          {{ event.name }}
        </div>
        <div class="notice-modal-notice-header">
          <div class="notice-modal-notice-published-at">
            {{ $parseDate(notice.announcement.published_at, 'yyyy-MM-dd') }}
          </div>
          <div class="notice-modal-notice-title">
            {{ notice.announcement.title }}
          </div>
        </div>
        <div class="notice-modal-notice-content"
             v-html="notice.announcement.content"></div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "../store/types";
import {Event} from "../store/models";

export default {
  name: 'NoticeModal',
  props: {
    shown: Boolean,
    receiptId: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);
    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });
    watch(() => dialogShown.value, (newValue) => {
      if (!newValue) {
        emit('dismiss')
      } else {
        getNotice();
      }
    });

    const notice = ref(null);
    const event = computed(() => {
      if (notice.value) {
        return Event.find(notice.value.announcement.event);
      }
    })
    const getNotice = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/notices/',
        params: {
          receipt_id: props.receiptId,
        }
      });

      if (response.body.success) {
        notice.value = response.body.notice;
      }
    }

    return {
      dialogShown,

      notice,
      event,
    }
  }
}
</script>

<style lang="less">
.notice-modal {
  height: 100%;
  background: #f4f4f4;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .notice-modal-top-bar {
    flex: 0 0 auto;
    border-bottom: solid 3px var(--v-primary-base);
    display: flex;

    .v-toolbar__content {
      flex: 1 1 0;
    }

    .notice-modal-back-btn {
      color: black;
      font-size: 88.88%;
      display: flex;
    }

    .notice-modal-title {
      flex: 1 1 0;
      text-align: center;
    }
  }

  .notice-modal-content-wrapper {
    flex: 1 1 auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .notice-modal-course-name {
      flex: 0 0 auto;
      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
      background-color: #f1e6e6;
      padding: 12px;
      font-weight: 500;
    }

    .notice-modal-notice-header {
      flex: 0 0 auto;
      padding: 12px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);

      .notice-modal-notice-published-at {
        text-align: right;
        font-size: 77.77%;
      }

      .notice-modal-notice-title {
        font-size: 133.33%;
        font-weight: 500;
      }
    }

    .notice-modal-notice-content {
      flex: 1 1 0;
      padding: 12px;
      overflow: auto;
    }
  }
}
</style>
