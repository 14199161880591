import {ACTION_TYPES} from "../types";
import {v4 as uuid} from "uuid";
import {Attendance, Event, Notice} from "../models";

export const apiModule = {
    state: {
        baseUrl: '/api/',
        requests: {},
        loadingMessage: null,
    },
    mutations: {
        startRequest(state, {uid, record}) {
            state.requests = {
                ...state.requests,
                [uid]: record,
            };
        },
        endRequest(state, uid) {
            let d = state.requests;
            delete d[uid];
            state.requests = {...d};
        },
        clearRequest(state) {
            state.requests = {};
        },
        setLoadingMessage(state, msg) {
            state.loadingMessage = msg;
        },
    },
    actions: {
        [ACTION_TYPES.START_LOADING]: {
            root: true,
            handler({commit}, {uid, record, timeout}) {
                commit('startRequest', {uid, record});

                if (timeout !== -1) {
                    setTimeout(() => {
                        commit('endRequest', uid);
                    }, timeout || 30000);
                }
            }
        },
        [ACTION_TYPES.STOP_LOADING]: {
            root: true,
            handler({commit}, uid) {
                commit('endRequest', uid);
            }
        },
        [ACTION_TYPES.CLEAR_LOADING]: {
            root: true,
            handler({commit}) {
                commit('clearRequest');
            }
        },
        [ACTION_TYPES.SET_LOADING_MSG]: {
            root: true,
            handler({commit}, msg) {
                commit('setLoadingMessage', msg);
            }
        },
        [ACTION_TYPES.CALL_API]: {
            root: true,
            async handler({state, dispatch}, {url, params, opt}) {
                const {not_blocking, throw_error, extra} = {
                    not_blocking: false,
                    throw_error: false,
                    extra: null,
                    ...opt,
                };
                const uid = uuid();

                if (!not_blocking) {
                    dispatch(ACTION_TYPES.START_LOADING, {
                        uid, record: {url, params}, timeout: 5 * 60 * 1000,
                    });
                }

                try {
                    const response = await this._vm.$http.post(state.baseUrl + url, params, {timeout: 5 * 60 * 1000, ...extra});
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }
                    return response;
                } catch (response) {
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }

                    if (throw_error) {
                        throw response;
                    } else if (response.status === 401 || response.status === 302) {
                        dispatch(ACTION_TYPES.LOGOUT);
                    } else {
                        this._vm.$handleErrorMessage(response)
                        return false
                    }
                }
            },
        },

        [ACTION_TYPES.GET_COURSES]: {
            root: true,
            async handler({dispatch, commit}, eventId) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/events/',
                    params: {
                        'event_id': eventId,
                    },
                });

                if (response.body.events) {
                    await Event.insert({
                        data: response.body.events,
                    })
                }

                if (response.body.event) {
                    await Event.insert({
                        data: response.body.event,
                    })
                }

                return response;
            },
        },

        [ACTION_TYPES.GET_ATTENDANCE]: {
            root: true,
            async handler({dispatch, commit}, eventId) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/events/attendance/',
                    params: {
                        'event_id': eventId,
                    },
                });

                if (response.body.attendances) {
                    await Attendance.create({
                        data: response.body.attendances,
                    })
                }
            },
        },

        [ACTION_TYPES.GET_NOTICE]: {
            root: true,
            async handler({dispatch, commit}, set_read) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'client/notices/',
                    params: {
                        set_read: set_read,
                    }
                });

                if (response.body.notices) {
                    await Notice.insert({
                        data: response.body.notices,
                    })
                }
            },
        },
    },
    getters: {
        requests(state) {
            return state.requests;
        },
        requestCount(state) {
            return Object.keys(state.requests).length;
        },
        loadingMessage(state) {
            return state.loadingMessage;
        },
    },
}
