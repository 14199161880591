<template>
  <v-app>
    <v-main>
      <router-view class="app-content"
                   v-if="account"/>
    </v-main>

    <v-bottom-navigation app
                         grow
                         color="primary">
      <v-btn :to="{name: 'my-courses'}"
             exact>
        <span>My Courses</span>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-btn :to="{name: 'notices'}"
             exact>
        <span>Notices</span>
        <v-badge dot
                 offset-y="8"
                 color="primary"
                 :value="unreadNoticeCount > 0">
          <v-icon>mdi-email-outline</v-icon>
        </v-badge>

      </v-btn>
      <v-btn :to="{name: 'scan-qr-code'}"
             exact
             v-if="$isTeamNote()">
        <span>Scan QR Code</span>
        <v-icon>mdi-qrcode-scan</v-icon>
      </v-btn>
<!--      <v-btn @click="logout"-->
<!--             exact>-->
<!--        <span>LOGOUT</span>-->
<!--        <v-icon>mdi-logout</v-icon>-->
<!--      </v-btn>-->
    </v-bottom-navigation>

    <v-snackbar v-model="snackBarShown"
                :timeout="3000">
      {{ snackBarContent }}
    </v-snackbar>

    <LoginModal :shown="loginDialogShown"></LoginModal>

    <v-overlay :value="isLoading"
               z-index="9999">
      <BounceLoader color="#ba1d21"></BounceLoader>
    </v-overlay>
  </v-app>
</template>

<script>

import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "./store/types";
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import LoginModal from "./components/LoginModal";
import {Notice} from "./store/models";

export default {
  name: 'App',
  components: {LoginModal, BounceLoader},
  setup(props, {root}) {
    const unreadNoticeCount = computed(() => {
      return Notice.query().where((n) => !n.read_at).count();
    })

    // Snack Bar
    const snackBarShown = ref(false);
    const snackBarContent = computed(() => root.$store.getters.snackBarContent);
    watch(() => root.$store.getters.snackBarContent, (content) => {
      if (content) {
        snackBarShown.value = true;
      }
    }, {immediate: true});
    watch(() => snackBarShown.value, (shown, oldShown) => {
      if (oldShown && !shown) {
        root.$store.dispatch(ACTION_TYPES.HIDE_SNACKBAR);
      }
    }, {immediate: true});

    // Loading Spinner
    const isLoading = computed(() => {
      return root.$store.getters.requestCount !== 0;
    });

    //
    const loginDialogShown = ref(false);
    const account = computed(() => {
      return root.$store.getters.account;
    });
    watch(() => account.value, async (newValue, oldValue) => {
      if (oldValue && !newValue) {
        console.log('LOGGED OUT');
        loginDialogShown.value = true;
        // root.$router.replace({name: 'my-courses'})
      }

      if (newValue && !oldValue) {
        console.log('LOGGED IN');
        loginDialogShown.value = false;
        root.$router.replace({name: 'my-courses'})
        await root.$store.dispatch(ACTION_TYPES.GET_COURSES);
        await root.$store.dispatch(ACTION_TYPES.GET_NOTICE);
      }
    });
    const logout = function () {
      root.$store.dispatch(ACTION_TYPES.LOGOUT);
    };

    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);

      // Check if id_token is present
      const urlParams = new URLSearchParams(window.location.search);
      const id_token = urlParams.get('id_token');

      if (id_token) {
        const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'jwt_login/',
          params: {jwt: id_token}
        })

        if (response.body.success) {
          history.replaceState(null, '', window.location.pathname + window.location.hash)
        }
      }

      try {
        await root.$store.dispatch(ACTION_TYPES.GET_ME);
        await root.$store.dispatch(ACTION_TYPES.GET_COURSES);
        await root.$store.dispatch(ACTION_TYPES.GET_NOTICE);
      } catch (e) {
        await root.$store.dispatch(ACTION_TYPES.SET_AFTER_LOGIN, root.$router.currentRoute.fullPath);
        // Check if it is TeamNote WebView
        if (root.$isTeamNote()) {
          root.$loginTeamNote(root.$router.currentRoute.fullPath);
        } else {
          loginDialogShown.value = true;

          if (new URLSearchParams(window.location.search).get('sso')) {
            root.$loginTeamNote();
          }
        }
      }
    })

    return {
      unreadNoticeCount,
      snackBarShown,
      snackBarContent,
      isLoading,
      loginDialogShown,
      account,
      logout,
    }
  }
};
</script>

<style lang="less">
.app-bar {
  .app-bar-tabs {
    .v-tab:before {
      transition: none !important;
    }

    .active {
      &::before {
        opacity: 1 !important;
        position: absolute;
        left: calc(50% - 10px);
        top: calc(100% - 10px);
        transform: rotate(45deg) !important;
        width: 20px;
        height: 20px;
        background-color: #eaeaea;
      }
    }
  }

  .app-bar-user-info {
    flex: 0 1 0;

    .logout-btn {
      cursor: pointer;
    }
  }
}

.v-main__wrap {
  display: flex;
  background: linear-gradient(180deg, rgba(234, 234, 234, 1) 0%, rgba(234, 234, 234, 1) 40%, rgba(244, 244, 244, 1) 40%, rgba(244, 244, 244, 1));
}
</style>
