<template>
  <div class="my-courses">
    <div class="my-courses-header">
      My Courses
      <v-btn icon
             x-large
             @click="userQrCodeShown = true"
             class="user-qr-code-btn">
        <v-icon>mdi-qrcode</v-icon>
      </v-btn>

      <UserQrCode v-model="userQrCodeShown"></UserQrCode>
    </div>
    <v-chip-group class="my-courses-status-chip-group"
                  active-class="active"
                  column
                  v-model="courseStatus">
      <v-chip value="enrolled">Enrolled</v-chip>
      <v-chip value="past">Past Courses</v-chip>
    </v-chip-group>
    <div class="my-courses-list">
      <v-card v-for="course in courses"
              :key="course.id"
              class="my-course-course">
        <v-card-text>
          <div class="my-courses-course-category">{{ course.category.name }}</div>
          <div class="my-courses-course-name">{{ course.name }}</div>
          <div class="my-courses-course-date">
            <v-icon color="primary">mdi-calendar-month-outline</v-icon>
            {{ $parseDate(course.start, 'yyyy-MM-dd') }} - {{ $parseDate(course.end, 'yyyy-MM-dd') }}
          </div>
          <div class="my-courses-course-venue">
            <v-icon color="primary">mdi-map-marker-outline</v-icon>
            {{ course.venue }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn block
                 color="primary"
                 @click="showEvent(course)">
            <v-icon class="mr-2">mdi-magnify</v-icon>
            Course Details
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <CourseModal :shown="eventModalShown"
                 :event-id="showingEventId"
                 @dismiss="eventModalShown = false"></CourseModal>
  </div>
</template>

<script>
import {computed, ref, onMounted} from "@vue/composition-api";
import {DateTime} from "luxon";
import {Event} from "../store/models";
import {ACTION_TYPES} from "../store/types";
import CourseModal from "../components/CourseModal";
import UserQrCode from "../components/UserQrCode.vue";

export default {
  name: 'MyCourses',
  components: {UserQrCode, CourseModal},
  setup(props, {root}) {
    const userQrCodeShown = ref(false);

    const courseStatus = ref('enrolled');

    const courses = computed(() => {
      const now = DateTime.fromJSDate(new Date());

      return Event.query().where((e) => {
        let eventEnd = DateTime.fromISO(e.end);

        return courseStatus.value === 'enrolled' ? now < eventEnd : eventEnd < now;
      }).orderBy('end', courseStatus.value === 'enrolled' ? 'asc' : 'desc').get();
    });

    const eventModalShown = ref(false);
    const showingEventId = ref(null);
    const showEvent = function (event) {
      showingEventId.value = event.id;
      eventModalShown.value = true;
    }

    onMounted(() => {
      root.$store.dispatch(ACTION_TYPES.GET_COURSES)
    })

    return {
      userQrCodeShown,
      courseStatus,
      courses,

      eventModalShown,
      showingEventId,
      showEvent,
    }
  },
}
</script>

<style lang="less">
.my-courses {
  flex: 1 1 auto;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;

  .my-courses-header {
    font-size: 200%;
    font-weight: 500;

    .user-qr-code-btn {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .my-courses-status-chip-group {
    .active {
      background-color: var(--v-primary-base);
      color: white;
      font-weight: 500;
    }
  }

  .my-courses-list {
    flex: 1 1 0;
    overflow-y: auto;

    .my-course-course {
      margin-bottom: 12px;

      .v-card__text {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .my-courses-course-category {
        color: var(--v-primary-base);
      }

      .my-courses-course-name {
        font-size: 133.33%;
        font-weight: 500;
        color: #000;
        line-height: 150%;
      }
    }
  }
}
</style>
