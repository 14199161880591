import Vue from 'vue'
import VueRouter from 'vue-router'
import MyCourses from "../views/MyCourses";
import Notices from "../views/Notices";
import ScanQrCode from "../views/ScanQrCode";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'my-courses',
        component: MyCourses
    },
    {
        path: '/notices',
        name: 'notices',
        component: Notices,
    },
    {
        path: '/scan',
        name: 'scan-qr-code',
        component: ScanQrCode,
    },
]

const router = new VueRouter({
    routes
})

export default router
