<template>
  <v-dialog v-model="shown">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <qrcode v-if="!$isTeamNote()"
                :value="$me().id" :options="{ width: 300 }"></qrcode>
        <qrcode v-if="$isTeamNote()"
                :value="qrCodeContent" :options="{ width: 300 }"></qrcode>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
import {onMounted, ref, watch} from "@vue/composition-api";

export default {
  name: 'UserQrCode',
  props: {
    value: Boolean,
  },
  setup(props, {root, emit}) {
    const qrCodeContent = ref(null);

    const shown = ref(false);
    watch(() => props.value, (newValue) => {
      shown.value = newValue;
    });

    watch(() => shown.value, (newValue) => {
      emit('input', newValue);
    });

    onMounted(() => {
      if (root.$isTeamNote()) {
        window.tnConnector.app.getProfileQrCodeContent({}, (result) => {
          qrCodeContent.value = result.content;
        }, (error) => {
          console.log('Error: ' + JSON.stringify(error));
        })
      }
    })

    return {
      qrCodeContent,
      shown,
    }
  }
}
</script>
