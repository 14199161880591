<template>
  <div class="scan-qr-code pa-0">
    <v-app-bar flat
               dense
               class="scan-qr-code-top-bar">
      <div class="scan-qr-code-title">Scan QR Code</div>
    </v-app-bar>
    <v-row no-gutters
           class="flex-grow-1">
      <v-col>
        <v-btn block
               plain
               x-large
               :ripple="false"
               @click="startTnQrCodeReader"
               style="height: 100% !important;"
               v-if="tnQrCodeReaderAvailable">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-icon size="60px">fas fa-camera</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Tap to Scan QR Code
              </v-col>
            </v-row>
          </v-container>
        </v-btn>
        <QrcodeStream :camera="qrCodeReaderSuspended? 'off' : 'auto'"
                      @init="onQrcodeStreamInit"
                      @decode="onQrCodeDecode"
                      class="qr-code-reader"
                      v-if="!tnQrCodeReaderAvailable && htmlCameraAvailable">
          <div class="qr-code-reader-overlay">
            <div class="qr-code-reader-hint">
              Scan the Lesson QR Code to Check-in
            </div>
          </div>
        </QrcodeStream>
      </v-col>
    </v-row>

    <CourseModal :event-id="showingEventId"
                 :shown="courseModalShown"
                 @dismiss="onCourseModalDismiss"></CourseModal>
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import {computed, onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "../store/types";
import jwt_decode, {InvalidTokenError} from "jwt-decode";
import {JWT_KEY} from "../constants";
import CourseModal from "../components/CourseModal";

export default {
  name: 'ScanQrCode',
  components: {CourseModal, QrcodeStream},
  setup(props, {root}) {
    const token = ref(null);

    const tnQrCodeReaderAvailable = computed(() => {
      return window.tnConnector && window.tnConnector.util.scanCode;
    });
    const startTnQrCodeReader = function () {
      window.tnConnector.util.scanCode({}, ({type, data}) => {
        onQrCodeDecode(data);
      }, async ({errorMessage, isCameraPermissionDenied, isCancelledByUser}) => {
        if (isCameraPermissionDenied) {
          await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Camera Permission Denied');
        }
      })
    };

    const htmlCameraAvailable = ref(true);
    const onQrcodeStreamInit = async function (promise) {
      try {
        const {capabilities} = await promise

        // successfully initialized
        htmlCameraAvailable.value = true;
      } catch (error) {
        // await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, error.name);
        htmlCameraAvailable.value = false;

        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    }
    const qrCodeReaderSuspended = ref(false);
    const onQrCodeDecode = async function (s) {
      qrCodeReaderSuspended.value = true;
      try {
        await handleJWT(s);
        // let hash = new URL(s).hash;
        // let jwt = hash.substr('#/scan/'.length)
        //
        // await handleJWT(jwt);
      } catch (e) {
        if (e instanceof TypeError) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid QR Code');
        }
      } finally {
        qrCodeReaderSuspended.value = false;
      }
    };

    // Handlers
    const handleJWT = async function (jwt) {
      try {
        let decoded = jwt_decode(jwt);

        if (!decoded[JWT_KEY.SESSION_ID]) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid JWT: ssn');
        }

        if (await root.$dialog.confirm({text: root.$t('scan_qr_code.checkin_confirm_msg'), showClose: false})) {
          const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
            url: 'client/checkin/',
            params: {
              token: jwt,
            },
          });

          if (response.body.success) {
            root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('scan_qr_code.checkin_success_msg'));
            showingEventId.value = response.body.attendance.enrollment.event;
            courseModalShown.value = true;
            qrCodeReaderSuspended.value = true;
          }
        }
      } catch (e) {
        if (e instanceof InvalidTokenError || e instanceof TypeError) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid QR Code');
        } else if (e.body?.msg) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t(`error_messages.${e.body.msg}`));
        } else {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('error_messages.default'));
        }
      }
    };

    const showingEventId = ref(null);
    const courseModalShown = ref(false);
    const onCourseModalDismiss = function () {
      showingEventId.value = null;
      courseModalShown.value = false;
      qrCodeReaderSuspended.value = false;
    }


    onMounted(() => {
      if (root.$route.params.token) {
        handleJWT(root.$route.params.token);
      }
    })

    return {
      tnQrCodeReaderAvailable,
      startTnQrCodeReader,

      htmlCameraAvailable,
      onQrcodeStreamInit,
      qrCodeReaderSuspended,
      onQrCodeDecode,

      showingEventId,
      courseModalShown,
      onCourseModalDismiss,
    }
  }
}
</script>

<style lang="less">
.scan-qr-code {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;

  .scan-qr-code-top-bar {
    flex: 0 0 auto;
    border-bottom: solid 3px var(--v-primary-base);
    display: flex;

    .v-toolbar__content {
      flex: 1 1 0;
    }

    .scan-qr-code-title {
      flex: 1 1 0;
      text-align: center;
    }
  }

  .qr-code-reader {
    .qr-code-reader-overlay {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;

      .qr-code-reader-hint {
        flex: 0 0 auto;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 12px;
        border-radius: 100px;
        margin: 12px auto 0;
      }
    }
  }
}
</style>
