<template>
  <v-dialog v-model="dialogShown"
            transition="scroll-x-transition"
            fullscreen
            persistent
            class="pa-0">
    <div class="evaluation-modal">
      <v-app-bar flat
                 dense
                 class="evaluation-modal-top-bar">
        <div class="evaluation-modal-back-btn"
             @click="$emit('dismiss')">
          <v-icon>mdi-chevron-left</v-icon>
        </div>
        <div class="evaluation-modal-title">Course Evaluation</div>
        <div class="evaluation-modal-save-btn"
             @click="$refs.form.validate() && submit()">
          SAVE
        </div>
      </v-app-bar>
      <div class="evaluation-modal-content-wrapper">
        <div class="course-evaluation-form" v-if="evaluation">
          <v-form ref="form">
            <div :class="['course-evaluation-form-question', question.type]"
                 v-for="question in evaluation.questions"
                 :key="question.id">
              <v-input hide-details="auto"
                       :rules="question.required ? requiredRules : []"
                       v-model="answers[question.id]">
                <div class="d-flex flex-column">
                  <div class="course-evaluation-form-question-title">
                    {{ question.title }}
                    <span v-if="question.required"
                          style="color: red;">(*)</span>
                  </div>

                  <div class="course-evaluation-form-question-text"
                       v-if="question.type === EVALUATION_QUESTION_TYPE.TEXT">
                    <v-text-field outlined
                                  hide-details="auto"
                                  v-model="answers[question.id]"></v-text-field>
                  </div>

                  <div class="course-evaluation-form-question-longtext"
                       v-if="question.type === EVALUATION_QUESTION_TYPE.LONGTEXT">
                    <v-textarea outlined
                                hide-details="auto"
                                v-model="answers[question.id]"
                                no-resize
                                auto-grow></v-textarea>
                  </div>

                  <div class="course-evaluation-form-question-checkbox"
                       v-if="question.type === EVALUATION_QUESTION_TYPE.CHECKBOX">
                    <v-checkbox hide-details="auto"
                                v-for="option in questionOptions(question)"
                                :label="option"
                                :value="option"
                                multiple
                                v-model="answers[question.id]">
                    </v-checkbox>
                  </div>

                  <div class="course-evaluation-form-question-radio"
                       v-if="question.type === EVALUATION_QUESTION_TYPE.RADIO">
                    <v-radio-group hide-details="auto"
                                   v-model="answers[question.id]">
                      <v-radio v-for="option in questionOptions(question)"
                               :label="option"
                               :value="option">
                      </v-radio>
                    </v-radio-group>
                  </div>

                  <div class="course-evaluation-form-question-dropdown"
                       v-if="question.type === EVALUATION_QUESTION_TYPE.DROPDOWN">
                    <v-select outlined
                              hide-details="auto"
                              :items="questionOptions(question)"
                              v-model="answers[question.id]"></v-select>
                  </div>
                </div>
              </v-input>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "../store/types";
import {Event} from "../store/models";
import {EVALUATION_QUESTION_TYPE} from "../constants";

export default {
  name: 'EvaluationModal',
  props: {
    shown: Boolean,
    courseId: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);
    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });
    watch(() => dialogShown.value, (newValue) => {
      if (!newValue) {
        emit('dismiss')
      } else {
        answers.value = {};
      }
    });


    const event = computed(() => {
      if (props.shown && props.courseId) {
        return Event.query().find(props.courseId);
      }
    })
    const evaluation = computed(() => {
      if (event.value) return event.value.evaluation;
    });
    const questionOptions = function (question) {
      return JSON.parse(question.options);
    }
    const requiredRules = ref([
      value => !!value || 'Required',
    ]);
    const answers = ref({});

    const submit = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/submit_evaluation/',
        params: {
          event_id: props.courseId,
          answers: answers.value,
        },
      })

      if (response.body.success) {
        root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Response Submitted Successfully');
        emit('dismiss');
      }
    };

    return {
      dialogShown,

      event,
      evaluation,
      questionOptions,
      requiredRules,
      answers,

      EVALUATION_QUESTION_TYPE,

      submit,
    }
  }
}
</script>

<style lang="less">
.evaluation-modal {
  height: 100%;
  background: #f4f4f4;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .evaluation-modal-top-bar {
    flex: 0 0 auto;
    border-bottom: solid 3px var(--v-primary-base);
    display: flex;

    .v-toolbar__content {
      flex: 1 1 0;
    }

    .evaluation-modal-back-btn {
      color: black;
      font-size: 88.88%;
      display: flex;
    }

    .evaluation-modal-title {
      flex: 1 1 0;
      text-align: center;
    }

    .evaluation-modal-save-btn {
      color: var(--v-primary-base);
      font-size: 88.88%;
      display: flex;
    }
  }

  .evaluation-modal-content-wrapper {
    flex: 1 1 auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .course-evaluation-form {
      padding: 8px;

      .course-evaluation-form-question {
        margin: 32px 0;

        &.LABEL {
          .course-evaluation-form-question-title {
            font-weight: 500;
            font-size: 122.22%;
          }
        }
      }
    }
  }
}
</style>
