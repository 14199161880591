<template>
  <div class="course-material-table-wrapper">
    <v-data-table :headers="headers"
                  :items="fileTree"
                  :item-class="getItemClass"
                  mobile-breakpoint="-1"
                  @click:row="onRowClick"
                  hide-default-header
                  hide-default-footer>
      <template #item.folder_expand="{item}">
        <v-btn icon
               v-if="!item.file"
               @click="toggleFolderExpand(item.id)"
               :class="['course-material-folder-expand-btn', {'active': expandedFolders.indexOf(item.id) !== -1}]">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template #item.name="{item}">
        <div class="course-material-table-title-wrapper">
          <v-btn icon
                 v-if="!item.file || item.parent"
                 @click="toggleFolderExpand(item.id)"
                 :class="['course-material-folder-expand-btn', {'active': expandedFolders.indexOf(item.id) !== -1}, {'invisible': item.parent}]">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          <v-icon v-if="item.file"
                  class="course-material-file-icon">
            {{ getFileIcon(item.file.content_type) }}
          </v-icon>
          <div :class="['d-flex', 'flex-grow-1', {'flex-column': item.file}]">
            <div :class="item.file ? 'course-material-file-name' : 'course-material-folder-name'">{{ item.name }}</div>
            <div class="course-material-file-size" v-if="item.file">{{ $parseFileSize(item.file.size) }}</div>
          </div>
          <div class="course-material-folder-file-count" v-if="!item.file">
            {{ $t('material.folder_file_count', {count: item.children.length}) }}
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import _ from 'lodash';
import {ACTION_TYPES} from "../store/types";

export default {
  name: 'CourseMaterial',
  props: {
    courseId: String,
  },
  setup(props, {root}) {
    const headers = ref([
      {text: root.$t('file_upload.name'), value: 'name', sortable: false},
    ]);
    const folders = ref([]);
    const filesByFolder = ref({});
    const expandedFolders = ref([]);
    const toggleFolderExpand = function (folderId) {
      const idx = expandedFolders.value.indexOf(folderId);
      if (idx === -1) {
        expandedFolders.value.push(folderId);
      } else {
        expandedFolders.value.splice(idx, 1);
      }
    };
    const fileTree = computed(() => {
      let tree = [];

      folders.value.forEach((folder) => {
        tree.push(folder);
        let childFiles = filesByFolder.value[folder.id];

        if (expandedFolders.value.indexOf(folder.id) !== -1 && childFiles) {
          _.sortBy(childFiles, 'name').forEach((f) => {
            tree.push(f);
          })
        }
      });

      return tree;
    })
    const getItemClass = function (item) {
      return item.file ? '' : 'folder';
    }
    const getFileIcon = function (mime) {
      if (mime.startsWith('image/')) return 'far fa-file-image';

      return {
        'application/pdf': 'far fa-file-pdf',
        'application/vnd.ms-powerpoint': 'far fa-file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'far fa-file-powerpoint',
        'application/vnd.ms-excel': 'far fa-file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'far fa-file-excel',
        'application/msword': 'far fa-file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'far fa-file-word',
      }[mime] || 'far fa-file';
    }
    const getFileType = function (mime) {
      if (mime.startsWith('image/')) return 'Image';

      return {
        'application/pdf': 'PDF',
        'application/vnd.ms-powerpoint': 'PowerPoint Presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint Presentation',
        'application/vnd.ms-excel': 'Excel Spreadsheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Spreadsheet',
        'application/msword': 'Word Document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document',
      }[mime] || 'Other File';
    }
    const getMaterials = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/events/material/',
        params: {
          filters: {
            'event.id': props.courseId,
          },
        },
      });

      if (response.body.success) {
        let files = response.body.materials;

        let fldrs = _.remove(files, (a) => !a.parent);

        filesByFolder.value = _.groupBy(files, 'parent');

        folders.value = _.sortBy(fldrs, 'name').map((f) => {
          return {
            ...f,
            children: filesByFolder.value[f.id] || [],
          }
        });
      }
    }

    watch(() => props.courseId, (newValue) => {
      getMaterials();
    })

    const onRowClick = async function (evt, {item}) {
      if (item.file) {
        if (root.$isTeamNote()) {
          root.$store.dispatch(ACTION_TYPES.START_LOADING, {uid: 'course-material'});
          let path = `file/${item.file.id}`;

          let response = await this.$store.dispatch(ACTION_TYPES.CALL_API, {
            url: path,
            params: {},
            opt: {extra: {responseType: 'arraybuffer'}},
          });
          let blob = new Blob([response.data], {type: response.headers.get('content-type')});
          let binaryString = await root.$convertFileToBinaryString(blob);
          window.tnConnector.app.writeFile({
            filename: item.file.filename,
            data: binaryString,
          }, ({filename}) => {
            const openFunc = item.file.content_type.startsWith('image') ? window.tnConnector.app.openImages :
                (item.file.content_type.startsWith('image') ? window.tnConnector.app.openVideo : window.tnConnector.app.openDocument);

            openFunc({
              filename,
              filenames: [filename],
              mimeType: item.file.content_type,
              isAllowDownload: true,
            }, () => {
              root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
            }, (error) => {
              alert(JSON.stringify(error))
              root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
            })
          }, (error) => {
            alert(JSON.stringify(error))
            root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
          })
        } else {
          root.$downloadFile(item.file.id);
        }
      }
    }

    onMounted(() => {
      getMaterials();
    })

    return {
      headers,
      folders,
      expandedFolders,
      toggleFolderExpand,
      fileTree,
      getItemClass,
      getFileIcon,
      getFileType,

      onRowClick,
    }
  }
}
</script>

<style lang="less">
.course-material-table-wrapper {
  padding-top: 12px;

  .folder {
    background-color: rgba(255, 221, 221, 0.2);

    .child-file-count {
      color: #C11A1E;
    }
  }

  .course-material-table-title-wrapper {
    display: flex;
    align-items: center;

    .course-material-folder-expand-btn {
      color: black;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;

      &.active {
        transform: rotate(-180deg);
      }

      &.invisible {
        opacity: 0;
        width: 18px;
      }
    }

    .course-material-file-icon {
      font-size: 24px;
      width: 36px;

      &.fa-file-pdf {
        color: #E12F34;
      }

      &.fa-file-word {
        color: #3B61D9;
      }

      &.fa-file-excel {
        color: #239E62;
      }

      &.fa-file-powerpoint {
        color: #EF6C00;
      }
    }

    .course-material-file-name {
      font-weight: 500;
    }

    .course-material-file-size {
      opacity: 0.5;
    }

    .course-material-folder-name {
      font-weight: 500;
      font-size: 114%;
      flex: 1 1 0;
    }

    .course-material-folder-file-count {
      color: var(--v-primary-base);
    }
  }
}
</style>
