export const ACTION_TYPES = {
    // loadingSpinner
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    CLEAR_LOADING: 'CLEAR_LOADING',
    SET_LOADING_MSG: 'SET_LOADING_MSG',

    // snackBar
    SHOW_SNACKBAR: 'SHOW_SNACKBAR',
    HIDE_SNACKBAR: 'HIDE_SNACKBAR',

    // API
    CALL_API: 'CALL_API',

    // Account
    SET_ME: 'SET_ME',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    GET_ME: 'GET_ME',
    SET_AFTER_LOGIN: 'SET_AFTER_LOGIN',
    CLEAR_AFTER_LOGIN: 'CLEAR_AFTER_LOGIN',

    GET_COURSES: 'GET_COURSES',
    GET_ATTENDANCE: 'GET_ATTENDANCE',
    GET_NOTICE: 'GET_NOTICE',
}
