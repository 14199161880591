<template>
  <v-dialog v-model="dialogShown"
            transition="scroll-x-transition"
            fullscreen
            persistent
            class="pa-0">
    <div class="course-modal">
      <v-app-bar flat
                 dense
                 class="course-modal-top-bar">
        <div class="course-modal-back-btn"
             @click="$emit('dismiss')">
          <v-icon class="mr-4">mdi-chevron-left</v-icon>
          My Courses
        </div>
      </v-app-bar>
      <div class="course-modal-content-wrapper">
        <v-card class="course-modal-course-info"
                v-if="course">
          <v-card-text>
            <div class="course-modal-course-category">{{ course.category.name }}</div>
            <div class="course-modal-course-name">{{ course.name }}</div>
            <div class="course-modal-course-date">
              <v-icon color="primary">mdi-calendar-month-outline
              </v-icon>
              <DisplayTextField label="Class Date"
                                :value="$parseDate(course.start, 'yyyy-MM-dd') + ' - ' + $parseDate(course.end, 'yyyy-MM-dd')"></DisplayTextField>
            </div>
            <div class="course-modal-course-venue">
              <v-icon color="primary">mdi-map-marker-outline</v-icon>
              <DisplayTextField label="Venue"
                                :value="course.venue"></DisplayTextField>
            </div>
            <v-row class="course-modal-course-status-row">
              <v-col cols="8"
                     class="course-modal-course-status">
                <v-icon color="primary">mdi-walk</v-icon>
                <DisplayTextField label="Walk-in Availability"
                                  :value="course.can_walk_in ? 'Yes': 'No'"></DisplayTextField>
              </v-col>
              <v-col cols="4"
                     class="course-modal-course-status">
                <DisplayTextField label="Status"
                                  value="Active"></DisplayTextField>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-tabs class="course-modal-tabs"
                hide-slider
                center-active
                :show-arrows="false"
                v-model="tabIndex">
          <v-tab :ripple="false">Lessons</v-tab>
          <v-tab-item>
            <CourseLessons :course-id="eventId"
                           v-if="tabIndex === 0"></CourseLessons>
          </v-tab-item>
          <v-tab :ripple="false">Materials</v-tab>
          <v-tab-item>
            <CourseMaterial :course-id="eventId"
                            v-if="tabIndex === 1"></CourseMaterial>
          </v-tab-item>
          <v-tab :ripple="false">Notices</v-tab>
          <v-tab-item>
            <NoticeList :course-id="eventId"
                        v-if="tabIndex === 2"></NoticeList>
          </v-tab-item>
        </v-tabs>
        <!--        <v-tabs-items class="course-modal-tab-items">-->
        <!--          <v-tab-item>-->

        <!--          </v-tab-item>-->
        <!--        </v-tabs-items>-->
      </div>
      <div class="course-modal-evaluation-btn"
           v-if="shouldShowEvaluation">
        <v-btn block
               color="primary"
               @click="evaluationModalShown = true">
          <v-icon class="mr-4">mdi-file-document-outline</v-icon>
          Fill Evaluation Form
        </v-btn>
        <EvaluationModal :shown="evaluationModalShown"
                         @dismiss="onEvaluationModalDismiss"
                         :course-id="eventId"></EvaluationModal>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {ref, watch, computed} from "@vue/composition-api";
import {Event} from "../store/models";
import DisplayTextField from "./DisplayTextField";
import CourseMaterial from "./CourseMaterial";
import NoticeList from "./NoticeList";
import {ACTION_TYPES} from "../store/types";
import CourseLessons from "./CourseLessons";
import EvaluationModal from "./EvaluationModal";
import {DateTime} from "luxon";

export default {
  name: 'CourseModal',
  components: {EvaluationModal, CourseLessons, NoticeList, CourseMaterial, DisplayTextField},
  props: {
    eventId: String,
    shown: Boolean,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);
    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });
    watch(() => dialogShown.value, (newValue) => {
      if (!newValue) {
        emit('dismiss')
      } else {
        getCourse();
      }
    });

    const course = computed(() => {
      if (props.eventId) {
        return Event.query().find(props.eventId);
      }
    });
    const getCourse = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.GET_COURSES, props.eventId);

      if (response.body.success) {
        evaluationSubmitted.value = response.body.submitted_evaluation;
      }
    }

    const tabIndex = ref(null);

    const evaluationSubmitted = ref(false);
    const shouldShowEvaluation = computed(() => {
      if (!evaluationSubmitted.value && course.value?.evaluation?.published_at) {
        let publishedAt = DateTime.fromISO(course.value.evaluation.published_at);
        return publishedAt <= DateTime.fromJSDate(new Date());
      }
    })
    const evaluationModalShown = ref(false);
    const onEvaluationModalDismiss = function () {
      evaluationModalShown.value = null;
      getCourse()
    }

    return {
      dialogShown,
      course,
      tabIndex,
      shouldShowEvaluation,
      evaluationSubmitted,
      evaluationModalShown,
      onEvaluationModalDismiss,
    }
  },
}
</script>

<style lang="less">
.course-modal {
  height: 100%;
  background: #f4f4f4;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .course-modal-top-bar {
    flex: 0 0 auto;
    border-bottom: solid 3px var(--v-primary-base);

    .course-modal-back-btn {
      color: black;
      font-size: 88.88%;
    }
  }

  .course-modal-content-wrapper {
    flex: 1 1 auto;
    overflow-y: scroll;

    .course-modal-course-info {
      margin: 12px;

      .v-card__text {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .course-modal-course-category {
        color: var(--v-primary-base);
      }

      .course-modal-course-name {
        font-size: 133.33%;
        font-weight: 500;
        color: #000;
        line-height: 150%;
      }

      .course-modal-course-date, .course-modal-course-venue, .course-modal-course-status {
        display: flex;

        .v-icon {
          margin-right: 8px;
        }
      }
    }

    .course-modal-tabs {
      //flex: 0 0 auto;
      position: sticky;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      .v-tabs-bar {
        background: transparent !important;

        .v-slide-group__prev, .v-slide-group__next {
          flex: 0 1 12px !important;
          min-width: 12px;
        }

        .v-tab {
          background-color: #f9f9f9;
          border-radius: 10px 10px 0 0;
          margin-right: 2px;

          &.v-tab--active {
            background-color: #ffffff;
            color: black;

            &::before {
              display: none;
            }
          }
        }
      }

      .v-tabs-items {
        flex: 1 1 auto;

        .v-window__container {
          height: 100%;

          .v-window-item {
            height: 100%;
          }
        }
      }
    }
  }

  .course-modal-evaluation-btn {
    padding: 12px;
  }
}
</style>
